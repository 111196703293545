import React from 'react';
import PropTypes from 'prop-types';

const Breadcrumbs = ({ post, location }) => {
  const pathnames = location.pathname.split('/');
  const parent = pathnames[1];
  const title = pathnames[2];

  return (
    <ul className="breadcrumbs">
        <li className="parent-breadcumb">
            <a href={'/' + parent}>{parent}</a>
        </li>
        <li className="title-breadcumb">
            <a href={'/' + parent + '/' + title}>{post?.title}</a>
        </li>
    </ul>
  );
};

Breadcrumbs.propTypes = {
  post: PropTypes.shape({
		title: PropTypes.string,
	}),
  location: PropTypes.object
};

export default Breadcrumbs;
