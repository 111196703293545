import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

import { MediaImageType, TagType } from '../../../types';
import MediaImage from '../../Elements/MediaImage';
import Tag from '../../Elements/Tag';
import Breadcrumbs from '../../Elements/Breadcrumbs';
import ButtonBack from '../../Elements/ButtonBack';

const BlogPostHeader = ({ post, location }) => {
  return(
    <div className="hero image-hero">
      <ButtonBack />
      <BackgroundImage
        Tag='div'
        className="image-hero-background"
        fluid={post.relationships.image.relationships.image.localFile.childImageSharp.fluid}
        >
        <div className="inner-image-hero"></div>
      </BackgroundImage>
      <section className="section breadcrumbs-section">
        <div className="container-small">
          <Breadcrumbs post={post} location={location} />
        </div>
      </section>
      <div className="header-blog-post">
        <section className="section">
          <div className="container container-post">

            <div className="tags">
              <span>Tags:</span>
              {post?.relationships?.tags.map((tag) => (
                <Tag tag={tag} key={tag.id} />
              ))}
            </div>

            <div className="author-full tile">
              {post?.relationships?.author?.relationships?.picture?.relationships?.image && <div className="avatar tile is-parent">
                <div className="avatar-inner">
                  <MediaImage media={post.relationships.author.relationships.picture.relationships.image} alt={post.relationships.author.relationships.picture.media.alt} title={post.relationships.author.relationships.picture.media.title} />
                </div>
              </div>}
              <div className="tile is-parent is-vertical post-info">
                {post?.relationships?.author?.name && <div className="author-name">{post.relationships.author.name}</div>}
                <div className="publish-date">{post.created}</div>
              </div>
            </div>

            {post?.title && <h1>{post.title}</h1>}

          </div>
        </section>

      </div>
    </div>
  );
};

BlogPostHeader.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    created: PropTypes.string,
    relationships: PropTypes.shape({
      author: PropTypes.shape({
        name: PropTypes.string,
        relationships: PropTypes.shape({
          picture: MediaImageType,
        })
      }),
      tags: PropTypes.arrayOf(TagType),
      image: MediaImageType
    })
  }),
  location: PropTypes.object
};

export default BlogPostHeader;
