import React, { useState, useEffect } from 'react';
import reactHtmlParser, { convertNodeToElement } from 'react-html-parser';

import MediaImage from '../components/Elements/MediaImage';

export const useReplaceInlineImages = (markup, images) => {
  const [updatedMarkup, setUpdatedMarkup] = useState(markup);

  useEffect(() => {
    const transform = (node, index) => {
      // @TODO: move this functionality from here.
      if (node.type === 'tag' && node.name === 'pre') {
        node.attribs.class += ' line-numbers';
        return convertNodeToElement(node, index, transform);
      }

      if (node.type === 'tag' && node.name === 'img') {
        const attributes = node.attribs || {};
        const uuid = attributes['data-entity-uuid'];
        const image = uuid ? images.find(image => image.id === uuid) : null;
        return image && <MediaImage key={index} media={image} alt={attributes.alt} title={attributes.title} />;
      }
    };

    setUpdatedMarkup(reactHtmlParser(markup, { transform }));
  }, [markup, images]);

  return updatedMarkup;
};
