import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';
import BlogPostHeader from '../components/Sections/Blog/BlogPostHeader';
import BlogPostBody from '../components/Sections/Blog/BlogPostBody';
import BlogPostRelatedThumbnail from '../components/Sections/Blog/BlogPostRelatedThumbnail';
import ScrollToTop from '../components/Elements/ScrollToTop';
import { BlogPostType } from '../types';
import { getOgImageMetatags } from '../helpers/getOgImageMetatags';
import { useSiteMetadata } from '../hooks/use-site-metadata';

import '../fragments/node/blogPostFull';

const { arrayOf, shape, string, object } = PropTypes;

const BlogPost = ({ data, location }) => {

  const { frontendURL } = useSiteMetadata();

  const metatags = data.nodeBlogPost.metatags;

  // Get OG Image metatag
  const ogImageMeta = getOgImageMetatags(data.nodeBlogPost?.relationships?.image?.ogImageMetatag?.image, frontendURL);
  if (ogImageMeta) metatags.push(...ogImageMeta);

  return (
    <Layout className="blog-post" location={location}>
      <SEO metatags={metatags}/>
      <BlogPostHeader post={data.nodeBlogPost} location={location} />
      <BlogPostBody body={data.nodeBlogPost.body} inlineFiles={data?.inlineFiles?.nodes} />
      <BlogPostRelatedThumbnail relatedBlogPosts={data?.relatedBlogPosts?.nodes} />
      <ScrollToTop />
    </Layout>
  );
};

export default BlogPost;

BlogPost.propTypes = {
  data: shape({
    nodeBlogPost: BlogPostType,
    inlineFiles: shape({
      nodes: arrayOf(shape({
        id: string,
        localFile: object
      }))
    })
  }),
  location: object

};

export const blogPostQuery = graphql`
  query BlogPostQuery($drupal_id: String!, $inlineFiles: [String], $tags: [Int]) {
    nodeBlogPost(drupal_id: {eq: $drupal_id}) {
      ...NodeBlogPostFull
    }
    inlineFiles: allFileFile(
      filter: { drupal_id: { in: $inlineFiles } }
    ) {
      nodes {
        id: drupal_id
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    relatedBlogPosts: allNodeBlogPost(
      filter: {drupal_id: {ne: $drupal_id}, relationships: {field_tags: {elemMatch: {drupal_internal__tid: {in: $tags}}}}},
      sort: {order: DESC, fields: created},
      limit: 3
    ) {
      nodes {
        ...NodeBlogPostTeaser
      }
    }
  }
`;
