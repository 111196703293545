import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Tag = ({ tag }) => {
  return (
    tag?.name && <div className="tag">
      <Link to={tag.path.alias}>{tag.name}</Link>
    </div>
  );
};

Tag.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.shape({
      alias: PropTypes.string,
    })
  })
};

export default Tag;
