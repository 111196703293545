import React, { useEffect } from 'react';
import { arrayOf, object, shape, string } from 'prop-types';
import Prism from 'prismjs';

import { useReplaceInlineImages } from '../../../hooks/use-replace-inline-images';

const BlogPostBody = ({ body, inlineFiles = [] }) => {
  let blogPostContent = body.processed || '';

  // Add the drop cap.
  blogPostContent = blogPostContent.replace(/^<p>(\S)/,'<p><span class="drop-cap">$1</span>');

  const updatedBody = useReplaceInlineImages(blogPostContent, inlineFiles);

  useEffect(() => {
    Prism.highlightAll();
  });

  return (
    <section className="section">
      <div className='container container-post'>
        {updatedBody}
      </div>
    </section>
  );
};

BlogPostBody.propTypes = {
  body: shape({
    processed: string
  }),
  inlineFiles: arrayOf(shape({
    id: string,
    localFile: object
  }))
};

export default BlogPostBody;
