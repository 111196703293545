import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { MediaImageType } from '../../../types';

const BlogPostRelatedThumbnail = ({ relatedBlogPosts }) => {
  console.log(relatedBlogPosts, 'xxx');
  return (
    <>
    <section className="section has-background-light">
      <div className='container container-post'>
        <h3 className="section-title pt-0">Related blog posts</h3>
        <div className='blog-posts-list columns is-variable is-4 is-multiline'>
          {relatedBlogPosts.map((relatedBlogPost, index) => (
            <>
              <div className='post-box column is-4' key={index}>
                <div className='header-post'>
                  <Link className="img-wrapper" to={relatedBlogPost.path.alias}>
                    <Img fluid={relatedBlogPost.relationships.image.relationships.image.localFile.childImageSharp.fluid} alt={relatedBlogPost.relationships.image.media.alt} title={relatedBlogPost.relationships.image.media.title} />
                  </Link>
                </div>
                <div className='content-post'>
                  <div className='inner'>
                    <h3><Link to={relatedBlogPost.path.alias}>{relatedBlogPost.title}</Link></h3>
                    <p>{relatedBlogPost.body.summary}</p>
                  </div>
                </div>
                <div className="footer-post">
                  <div className="author">
                    <div className="tile is-parent post-info">
                      <div className="author-name">{relatedBlogPost.relationships.author.name}</div>
                      <div className="publish-date">{relatedBlogPost.created}</div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </section>
    </>
  );
};

BlogPostRelatedThumbnail.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.shape({
      summary: PropTypes.string
    }),
    path: PropTypes.shape({
      alias: PropTypes.string
    }),
    created: PropTypes.string,
    relationships: PropTypes.shape({
      author: PropTypes.shape({
        name: PropTypes.string,
      }),
      image: MediaImageType
    })
  }),
  relatedBlogPosts: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.shape({
      summary: PropTypes.string
    }),
    path: PropTypes.shape({
      alias: PropTypes.string
    }),
    created: PropTypes.string,
    relationships: PropTypes.shape({
      author: PropTypes.shape({
        name: PropTypes.string,
      }),
      image: MediaImageType
    })
  }))
};

export default BlogPostRelatedThumbnail;
